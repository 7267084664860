import { isMobile } from "./helper";
import SiteHeader from "./siteHeader";
import { toggleMenu, addSticky, closeMenu } from "./navigation";
import FloatLabels from "./forms/float-labels";

window.addEventListener("load", () => {
  new SiteHeader();

  toggleMenu();

  if (isMobile()) {
    closeMenu();
    addSticky();
  }
});

window.onresize = () => {
  if (isMobile()) {
    closeMenu();
    addSticky();
  }
};
